import { ref, computed } from "vue";
import { defineStore } from "pinia";
import moment from 'moment-timezone';
import { USER_STORE_KEY } from "../shared/constants/storeKeys";

export const useUserStore = defineStore(USER_STORE_KEY, () => {
    const currentUser = ref(window._VUE_SERVER_DATA_.currentUser);
    const originalUser = ref(window._VUE_SERVER_DATA_.originalUser);

    const customer = computed(() => {
        return currentUser.value.customer;
    });

    const canPerform = (activity, minAccessLevel) => {
        if (!currentUser.value || !originalUser.value) {
            return false;
        }

        if (minAccessLevel) {
            if (minAccessLevel === "site" && originalUser.value.accessLevel !== "site") {
                return false;
            }

            if (minAccessLevel === "brand" && originalUser.value.accessLevel === "customer") {
                return false;
            }
        }

        return currentUser.value.permissions.includes(activity);
    };

    const hasFeature = (feature) => {
        if (!currentUser.value) {
            return false;
        }

        const currentUserHasFeature = Boolean(currentUser.value.features[feature]);
        const originalUserHasFeature = (
            originalUser.value &&
            Boolean(originalUser.value.features[feature])
        );

        return currentUserHasFeature || originalUserHasFeature;
    };

    const timezone = computed(() => {
        if (currentUser.value && currentUser.value.timezone) {
            return currentUser.value.timezone;
        }

        return moment.tz.guess();
    });

    return {
        currentUser,
        originalUser,
        canPerform,
        hasFeature,
        timezone,
        customer,
    };
});
