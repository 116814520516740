import { requests } from "../requests";

export const getUsersList = () => {
    return requests.get('/config/get_users_list');
};

export const getShowFmAgent = () => {
    return requests.get('/config/get_show_fm_agent');
};

export const getListCounterMeasuresPageData = () => {
    return requests.get('/config/get_list_countermeasures_page_data');
};

export const createImmediateMaintenance = ({ mins, server_id, servergroup_id }) => {
    return requests.post('/config/immediateMaintenance', null, { params: { mins, server_id, servergroup_id } });
};

export const createImmediateMaintenanceMultiple = ({ ids, mins }) => {
    return requests.post('/config/immediateMaintenanceMultiple', null, { params: { ids, mins } });
};

export const terminateMaintenanceSchedule = ({ maintenance_schedule_id, no_redirect }) => {
    return requests.post('/config/terminateMaintenanceSchedule', null, { params: { maintenance_schedule_id, no_redirect } });
};

export const deleteMaintenanceSchedule = ({ maintenance_schedule_id, no_redirect }) => {
    return requests.post('/config/deleteMaintenanceSchedule', null, { params: { maintenance_schedule_id, no_redirect } });
};

export const postKubernetesClusterReportConfig = (cluster_id, cluster_config) => {
    return requests.post('/config/editKubernetesClusterConfig', null, {
        params: {
            cluster_id,
            cluster_config,
        }
    });
};

export const getKubernetesClusterDelete = (cluster_id) => {
    return requests.get('/config/deleteKubernetesCluster', { params: { cluster_id } });
};
export const deleteApiKey = ({ id }) => {
    return requests.post('/config/deleteApiKey', null, { params: { id } });
};

export const getMergeTags = ({ ids }) => {
    return requests.get('/config/get_merge_tags', { params: { ids } });
};

export const mergeExistingTags = ({ ids, destinationId }) => {
    return requests.post('/config/mergeTagsExisting', {
        id_list: ids,
        destination_id: destinationId,
    });
};

export const mergeNewTags = ({ ids, destinationName }) => {
    return requests.post('/config/mergeTagsNew', {
        id_list: ids,
        destination_name: destinationName,
    });
};

export const getPublicReport = ({ id }) => {
    return requests.get('/config/get_public_report', { params: { id } });
};

export const editPublicReport = ({
    public_report_id,
    url_key,
    hostname,
    name,
    timeframe_in_days,
    sort_order,
    include_resources,
    hide_fqdn,
    require_login,
    hide_detail_link,
    template,
    element_ids,
}) => {
    return requests.post('/config/editPublicReportV2', {
        public_report_id,
        url_key,
        hostname,
        name,
        timeframe_in_days,
        sort_order,
        include_resources,
        hide_fqdn,
        require_login,
        hide_detail_link,
        template,
        element_ids,
    });
};

export const endSimulate = ({ compound_service_id, target_type }) => {
    return requests.post('/config/endSimulate', null, { params: { compound_service_id, target_type } });
};

export const deleteInstanceTag = ({ instance_type, instance_id, tag_name, compoundMetricTag }) => {
    return requests.post('/config/deleteInstanceTag', null, { params: { instance_type, instance_id, tag_name, compoundMetricTag } });
};

export const bulkUploadTagsAttributes = ({
    importTags,
    importAttributes,
    createTagsFromAttributes,
    replaceExisting,
    runMpw,
    file,
}) => {
    const formData = new FormData();

    formData.append("importTags", importTags);
    formData.append("importAttributes", importAttributes);
    formData.append("createTagsFromAttributes", createTagsFromAttributes);
    formData.append("replaceExisting", replaceExisting);
    formData.append("runMpw", runMpw);

    if (file instanceof File || !(file instanceof Object)) {
        formData.append("file", file);
    }

    return requests.post('/config/bulkUploadTagsAttributes', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const fetchMaintenanceCount = ({server_id, application_id}) => {
    return requests.get('/config/get_server_maintenance_schedule', {params:{server_id,application_id}});
}
